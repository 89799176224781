import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import Footer from "../../component/Footer";
import book2 from "../../../src/assets/new/images/Library/icse2.png";
import { useTranslation } from "react-i18next";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";

export default function Searchbook() {
  const location = useLocation();
  const { t } = useTranslation();
  const [searchResultss, setSearchResultss] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [ser, setSer] = useState("");
  const [isRequested, setIsRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bookque, setBookQue] = useState([]);
  const navic = useNavigate();
  // Extract search results from state passed through routing
  const { searchResults, searchQuery } = location.state || {};
  useEffect(() => {
    if (searchQuery) {
      setSer(searchQuery); // Set the search input to the query value from previous page
    }
    if (searchResults) {
      setSearchResultss(searchResults);
      setShowSearchResults(true);
    }
  }, [location.state]);
  const MyBookSearch = () => {
    let RequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      student_id: Params.student_id,
      search: ser,
    });
    simplePostCall(ApiConfig.MY_BOOK_SEARCH, RequestBody)
      .then((data) => {
        console.log(data.list, "booksearch");
        setSearchResultss(data.list);
        setShowSearchResults(true);
        // setBookQue(data.list);
      })
      .catch((error) => console.log(error, "error"));
  };
  const MyBooksQueue = (book_id, status) => {
    let RequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      student_id: Params.student_id,
      book_id: book_id,
      status: status,
    });

    simplePostCall(ApiConfig.MY_BOOK_QUEUE, RequestBody)
      .then((data) => {
        if (data.result === true) {
          console.log(data.list, "bookResult");
          setBookQue(data.list); // Update the queue state if needed]
          if (data.message == "queued successfully") {
            navic("/Library");
          }
        } else {
          // setMessages(data.message);
          console.log(data.message, "ver");
        }
      })
      .catch((error) => console.log(error, "error"));
  };
  //
  const handleRequestToggle = (book_id, isRequested) => {
    setIsLoading(true); // Start loading animation

    // Simulate a request (for example, calling an API)
    setTimeout(() => {
      const status = isRequested ? "queued" : "queue"; // Toggle status
      MyBooksQueue(book_id, status); // Pass book_id and status to MyBooksQueue
      setIsRequested(!isRequested); // Toggle between Request and Cancel
      setIsLoading(false); // Stop loading animation
      MyBookSearch();
    }, 3000); // Simulating a delay of 2 seconds
  };

  const resultsToDisplay = showSearchResults ? searchResultss : searchResults;
  return (
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <div className="page-body-wrapper sidebar-icon">
        <div className="page-body" id="container">
          <div className="container-fluid open-chat">
            <div className="chat-header">
              <div className="d-flex justify-content-end mb-3">
                <div className="form-outline col-8 p-0 librarayform-outline">
                  <input
                    type="search"
                    id="form1"
                    class="form-control "
                    placeholder={t("search")}
                    value={ser}
                    // onChange={(e) => setSearchQuery(e.target.value)}
                    onChange={(e) => {
                      setSer(e.target.value);
                      console.log(e.target.value, "Serach");
                    }}
                  />
                </div>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-mdb-ripple-init
                  onClick={MyBookSearch}
                >
                  <i class="fas fa-search"></i>
                </button>
              </div>

              <div className="search-results">
                {resultsToDisplay?.length > 0 ? (
                  <div className="card-body p-0">
                    <div className="row">
                      {resultsToDisplay.map((postDetail, index) => (
                        <div className="col-md-6" key={index}>
                          <div
                            className="card"
                            style={{
                              boxShadow: "0px 4px 1px rgba(0, 0, 0, 0.05)",
                              borderRadius: "15px",
                            }}
                          >
                            <div
                              className="d-flex libraryview"
                              style={{ gap: "10px" }}
                            >
                              <div>
                                <img
                                  className=""
                                  src={
                                    postDetail.book_image
                                      ? ApiConfig.NODE_URL +
                                        "/" +
                                        postDetail.book_image
                                      : book2
                                  }
                                  alt="Description"
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    border: "none",
                                    boxShadow: "none",
                                  }}
                                />
                              </div>
                              <div
                                className="mt-3 mr-2"
                                style={{ width: "800px" }}
                              >
                                <div className="d-flex justify-content-between align-items ">
                                  <h4 className="librarytitle">
                                    {postDetail.book_name}
                                  </h4>
                                  <h4
                                    className="libraryfine"
                                    style={{ color: "blue" }}
                                  >
                                    {postDetail.book_available}/
                                    {postDetail.total_book}
                                  </h4>
                                </div>
                                <div>
                                  <h4 className="libraryname">
                                    {t("by")} {postDetail.author_name}
                                  </h4>
                                </div>
                                <div className="d-flex justify-content-between align-items">
                                  <h4
                                    style={{
                                      fontSize: "12px",
                                      color:
                                        Number(postDetail.book_available) === 0
                                          ? "red"
                                          : "green",
                                    }}
                                  >
                                    {Number(postDetail.book_available) === 0
                                      ? `${t("Total Queue")}: ${
                                          postDetail.total_queue
                                        }`
                                      : `${t("Available")}`}
                                  </h4>

                                  {/* Show request button only when book_available is 0 */}
                                  {Number(postDetail.book_available) === 0 && (
                                    <h4
                                      style={{
                                        fontSize: "12px",
                                        color: "green",
                                      }}
                                    >
                                      <button
                                        className="req"
                                        onClick={() =>
                                          handleRequestToggle(
                                            postDetail.book_id,
                                            isRequested
                                          )
                                        }
                                        disabled={isLoading} // Disable button during loading
                                      >
                                        {
                                          isLoading
                                            ? isRequested
                                              ? t("cancelling") // Translated "Cancelling..."
                                              : t("requesting") // Translated "Requesting..."
                                            : Number(postDetail.total_queue) ===
                                              0
                                            ? isRequested
                                              ? t("cancel") // Translated "Cancel"
                                              : t("request") // Translated "Request"
                                            : t("cancel") // Translated "Cancel"
                                        }
                                      </button>
                                    </h4>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <p>{t("No results found")}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
