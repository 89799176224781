import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import "../../../src/assets/new/css/Studentlibrary.css";
// import Delete_icon from "../../assets/new/images/ToDo/tododelete.png";
import Delete_icon from "../../../src/assets/new/images/ToDo/tododelete.png";
import BookHistory from "../../StaticData/BookHistory_Card_Data.json";
// import MyBook from "../../StaticData/Mybook_Card_Data.json";
import book1 from "../../../src/assets/new/images/Library/icse1.png";
import book2 from "../../../src/assets/new/images/Library/icse2.png";
import book3 from "../../../src/assets/new/images/Library/icse3.png";
import { useTranslation } from "react-i18next";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { useNavigate } from "react-router-dom";
import Searchbook from "./Searchbook";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import BreadCrumbs from "../../component/BreadCrumbs";
import { toast, ToastContainer } from "react-toastify";

const DuLibrary = () => {
  const { i18n, t } = useTranslation();
  const [bookss, setBookss] = useState([]);
  const [bookhis, setBookHis] = useState([]);
  const [bookque, setBookQue] = useState([]);
  const [messages, setMessages] = useState("");
  const [ser, setSer] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isRequest, setIsRequest] = useState(true);
  const [bookStatus, setBookStatus] = useState({});
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState("");
  const [bookHistoryPage, setBookHistoryPage] = useState(1);
  const [totalHistory, setTotalHistory] = useState("");
  const [msg, setMsg] = useState("");
  const [activeTab, setActiveTab] = useState("myBooksTab");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const MyBooks = (pages) => {
    let RequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      student_id: Params.student_id,
      page_no: pages,
      page_limit: "5",
    });
    simplePostCall(ApiConfig.MY_BOOK, RequestBody)
      .then((data) => {
        let totals = data.next_page;
        setTotal(totals);

        if (data.result === true) {
          // Avoid appending duplicate data
          setBookss((prevdata) => [...prevdata, ...data.list]);
        } else {
          setMessages(data.message);
        }
        console.log(data.list, "bookResult");
      })
      .catch((error) => console.log(error, "error"));
  };

  const filterUniqueBooks = () => {
    const seen = new Set();
    return bookss.filter((book) => {
      if (seen.has(book.book_name)) {
        return false; // Skip duplicate books
      } else {
        seen.add(book.book_name); // Add book to seen set
        return true; // Include this book
      }
    });
  };

  // <----------------------------------->// <----------------------------------->
  const onPageLoad = () => {
    if (total !== false) {
      setPage(page + 1);
      let a = page + 1;
      MyBooks(a);
    }
  };
  //<--------------------------------->

  const MyBooksHistory = (page) => {
    let RequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      student_id: Params.student_id,
      page_no: page,
      page_limit: "5", // You can adjust this as needed
    });

    simplePostCall(ApiConfig.MY_BOOK_HISTORY, RequestBody)
      .then((data) => {
        setTotalHistory(data.next_page); // Set next page from the API response
        if (data.result === true) {
          setBookHis((prevData) => [...prevData, ...data.list]); // Append new data to existing data
        } else {
          setMessages(data.message);
        }
      })
      .catch((error) => console.log(error, "error"));
  };
  const filterUniqueBooksHistory = () => {
    const seen = new Set();
    return bookhis.filter((book) => {
      if (seen.has(book.book_name)) {
        return false; // Skip duplicate books
      } else {
        seen.add(book.book_name); // Add book to seen set
        return true; // Include this book
      }
    });
  };
  const onPageLoadHistory = () => {
    if (totalHistory !== false) {
      setBookHistoryPage(bookHistoryPage + 1); // Increment the page number for Book History
    }
  };
  const MyBooksQueue = (bookId, status) => {
    let RequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      student_id: Params.student_id,
      book_id: bookId,
      status: status,
    });

    simplePostCall(ApiConfig.MY_BOOK_QUEUE, RequestBody)
      .then((data) => {
        if (data.result === true) {
          console.log(data.list, "bookResult");
          setBookQue(data.list); // Update the queue state if needed
          setMsg(data.message);
        } else {
          setMessages(data.message);
          console.log(data.message, "ver");
        }
      })
      .catch((error) => console.log(error, "error"));
  };
  const MyBookSearch = () => {
    let RequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      student_id: Params.student_id,
      search: ser,
    });
    simplePostCall(ApiConfig.MY_BOOK_SEARCH, RequestBody)
      .then((data) => {
        console.log(data.list, "booksearch");
        setSearchResults(data.list);
        setShowSearchResults(true);

        // setBookQue(data.list);
        navigate("/search-results", {
          state: { searchResults: data.list, searchQuery: ser },
        });
      })
      .catch((error) => console.log(error, "error"));
  };

  console.log(bookss, "bookss");
  console.log(bookhis, "bookhis");
  console.log(bookque, "bookque");
  useEffect(() => {
    MyBooksHistory(bookHistoryPage);
  }, [bookHistoryPage]);

  useEffect(() => {
    MyBooks(1);
  }, []);
  useEffect(() => {
    MyBooksQueue();
  }, [bookque]);
  const handleCancelQueue = (bookId) => {
    const status = "queued"; // Or the status you wish to set after canceling the book

    // Cancel the book from the queue
    MyBooksQueue(bookId, status);

    // Immediately clear the current queue state to show that the item has been removed
    setBookQue((prevQueue) =>
      prevQueue.filter((item) => item.book_id !== bookId)
    );

    // Optionally, you can call MyBooksQueue to refetch the entire queue
    setBookQue([]); // Clear the current list first (optional)
    MyBooksQueue(); // Fetch the updated queue list after cancellation
    toast.success("Successfully ");
  };

  return (
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <div className="page-body-wrapper sidebar-icon">
        <div className="page-body" id="container">
          <BreadCrumbs />
          <div className="container-fluid open-chat">
            <div className="chat-header">
              <div className="d-flex justify-content-end mb-3">
                <div className="form-outline col-8 p-0 librarayform-outline">
                  <input
                    type="search"
                    id="form1"
                    class="form-control "
                    placeholder={t("search")}
                    value={ser}
                    // onChange={(e) => setSearchQuery(e.target.value)}
                    onChange={(e) => {
                      setSer(e.target.value);
                      console.log(e.target.value, "Serach");
                    }}
                  />
                </div>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-mdb-ripple-init
                  onClick={MyBookSearch}
                >
                  <i class="fas fa-search"></i>
                </button>
              </div>
              {showSearchResults ? (
                <div className="search-results">
                  <Searchbook />
                </div>
              ) : (
                <>
                  <div id="exTab1">
                    <div className="libraryborder"></div>
                    <ul className="nav-pills d-flex">
                      <li
                        className={`nav-item col-md-4 p-0 ${
                          activeTab === "myBooksTab" ? "active" : ""
                        }`}
                      >
                        <a
                          className="nav-link libraryh1"
                          id="myBooksTab"
                          onClick={() => handleTabClick("myBooksTab")}
                        >
                          {t("My Books")}
                        </a>
                      </li>
                      <li
                        className={`nav-item col-md-4 p-0 ${
                          activeTab === "bookHistoryTab" ? "active" : ""
                        }`}
                      >
                        <a
                          className="nav-link libraryh2"
                          id="bookHistoryTab"
                          onClick={() => handleTabClick("bookHistoryTab")}
                        >
                          {t("Book History")}
                        </a>
                      </li>
                      <li
                        className={`nav-item col-md-4 p-0 ${
                          activeTab === "bookQueueTab" ? "active" : ""
                        }`}
                      >
                        <a
                          className="nav-link libraryh3"
                          id="bookQueueTab"
                          onClick={() => handleTabClick("bookQueueTab")}
                        >
                          {t("Book Queue")}
                        </a>
                      </li>
                    </ul>
                    <div className="libraryborder1"></div>

                    <div className="tab-content clearfix mt-3">
                      <div
                        className={`tab-pane ${
                          activeTab === "myBooksTab" ? "active" : ""
                        }`}
                        id="1a"
                      >
                        {/* Books data */}

                        <div className="card-body p-0">
                          <InfiniteScroll
                            style={{
                              overflow: "hidden",
                            }}
                            dataLength={bookss?.length || 0}
                            next={onPageLoad}
                            hasMore={total !== false}
                            endMessage={
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: "20px",
                                  marginBottom: "15px",
                                }}
                              >
                                {bookss.length === 0 ? (
                                  <b></b> // Message displayed when no data is found
                                ) : (
                                  <b>{messages}</b> // Show the regular end message when there is data
                                )}
                              </p>
                            }
                          >
                            <div className="row">
                              {filterUniqueBooks().map((postDetail, index) => (
                                <div className="col-md-6" key={index}>
                                  <div
                                    className="card"
                                    style={{
                                      boxShadow:
                                        "0px 4px 1px rgba(0, 0, 0, 0.05)",
                                      borderRadius: "15px",
                                    }}
                                  >
                                    <div className="d-flex libraryview">
                                      <div>
                                        <img
                                          className=""
                                          src={
                                            postDetail.book_image
                                              ? ApiConfig.NODE_URL +
                                                "/" +
                                                postDetail.book_image
                                              : book2
                                          }
                                          alt="Description"
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                            border: "none",
                                            boxShadow: "none",
                                          }}
                                        />
                                      </div>
                                      <div
                                        className="mt-3 mr-2"
                                        style={{ width: "800px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items">
                                          <h4 className="librarytitle">
                                            {postDetail.book_name}
                                          </h4>
                                        </div>
                                        <div className="d-flex justify-content-between align-items">
                                          <h4 className="libraryname">
                                            {t("by")} {postDetail.author_name}
                                          </h4>
                                          <h4 className="libraryfine">
                                            {postDetail.fine === "No Dues"
                                              ? ""
                                              : `Fine: ${postDetail.fine}`}
                                          </h4>
                                        </div>
                                        <div className="d-flex justify-content-between align-items">
                                          <h4
                                            style={{
                                              fontSize: "12px",
                                              color:
                                                postDetail.fine === "No Dues"
                                                  ? "green"
                                                  : "red",
                                            }}
                                          >
                                            {t("Issued Date")}:
                                            {postDetail.book_issue_date}
                                          </h4>

                                          <h4
                                            style={{
                                              fontSize: "12px",
                                              color:
                                                postDetail.fine === "No Dues"
                                                  ? "green"
                                                  : "red",
                                            }}
                                          >
                                            {t("Due Date")}:
                                            {postDetail.book_due_date}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </InfiniteScroll>
                        </div>
                      </div>
                      <div
                        className={`tab-pane ${
                          activeTab === "bookHistoryTab" ? "active" : ""
                        }`}
                        id="2a"
                      >
                        {/* BookHistory data */}
                        <div className="card-body p-0">
                          <InfiniteScroll
                            style={{ overflow: "hidden" }}
                            dataLength={bookhis.length}
                            next={onPageLoadHistory}
                            hasMore={totalHistory !== false}
                            endMessage={
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: "20px",
                                  marginBottom: "15px",
                                }}
                              >
                                {bookhis.length === 0 ? (
                                  <b>No history found</b>
                                ) : (
                                  <b>{messages}</b>
                                )}
                              </p>
                            }
                          >
                            <div className="row">
                              {filterUniqueBooksHistory().map(
                                (postDetail, index) => (
                                  <div className="col-md-6" key={index}>
                                    <div
                                      className="card"
                                      style={{
                                        boxShadow:
                                          "0px 4px 1px rgba(0, 0, 0, 0.05)",
                                        borderRadius: "15px",
                                      }}
                                    >
                                      <div
                                        className="d-flex libraryview"
                                        style={{ gap: "10px" }}
                                      >
                                        <div>
                                          <img
                                            className=""
                                            src={
                                              postDetail.book_image
                                                ? ApiConfig.NODE_URL +
                                                  "/" +
                                                  postDetail.book_image
                                                : book1
                                            }
                                            alt="Description"
                                            style={{
                                              height: "100px",
                                              width: "100px",
                                              border: "none",
                                              boxShadow: "none",
                                            }}
                                          />
                                        </div>
                                        <div
                                          className="mt-3 mr-2"
                                          style={{ width: "800px" }}
                                        >
                                          <h4 className="librarytitle">
                                            {postDetail.book_name}
                                          </h4>

                                          <div>
                                            <h4 className="libraryname">
                                              {t("by")} {postDetail.author_name}
                                            </h4>
                                          </div>
                                          <div className="d-flex justify-content-between align-items">
                                            <h4 className="DateGray_Color">
                                              {t("Issued Date")}:
                                              {postDetail.book_issue_date}
                                            </h4>

                                            <h4 className="DateGray_Color">
                                              {t("Return Date")}:
                                              {postDetail.book_return_date}
                                            </h4>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </InfiniteScroll>
                        </div>
                      </div>
                      <div
                        className={`tab-pane ${
                          activeTab === "bookQueueTab" ? "active" : ""
                        }`}
                        id="3a"
                      >
                        {messages ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <h4 style={{ color: "red", fontSize: "20px" }}>
                              {messages}
                            </h4>
                          </div>
                        ) : (
                          <div className="card-body p-0">
                            <div className="row">
                              {bookque?.map((postDetail, index) => (
                                <div className="col-md-6" key={index}>
                                  <div
                                    className="card"
                                    style={{
                                      boxShadow:
                                        "0px 4px 1px rgba(0, 0, 0, 0.05)",
                                      borderRadius: "15px",
                                    }}
                                  >
                                    <div className="d-flex libraryview">
                                      <div>
                                        <img
                                          className=""
                                          src={book3}
                                          alt="Description"
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                            border: "none",
                                            boxShadow: "none",
                                          }}
                                        />
                                      </div>
                                      <div
                                        className="mt-3 mr-2"
                                        style={{ width: "800px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items">
                                          <h4 className="librarytitle">
                                            {postDetail.book_name}
                                          </h4>
                                          <h4
                                            className="DateGray_Color"
                                            style={{ color: "blue" }}
                                          >
                                            {postDetail.book_available}/
                                            {postDetail.total_book}
                                          </h4>
                                        </div>
                                        <div className="d-flex justify-content-between align-items">
                                          <h4 className="libraryname">
                                            {t("by")} {postDetail.author_name}
                                          </h4>
                                          <h4
                                            className="DateGray_Color"
                                            style={{ color: "red" }}
                                          >
                                            {postDetail.position}/
                                            {postDetail.total_queue}
                                          </h4>
                                        </div>

                                        <div className="d-flex justify-content-between align-items">
                                          <h4
                                            className="DateGray_Color"
                                            style={{ color: "green" }}
                                          >
                                            {t("Available")}
                                          </h4>
                                          <h4
                                            style={{
                                              fontSize: "12px",
                                              color: "green",
                                            }}
                                          >
                                            <button
                                              className="req"
                                              onClick={() =>
                                                handleCancelQueue(
                                                  postDetail.book_id
                                                )
                                              }
                                            >
                                              {t("Cancel")}
                                            </button>
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <ToastContainer style={{ marginTop: "50px" }} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DuLibrary;
