//import AsyncStorage from '@react-native-community/async-storage';

//import AppStrings from '../constants/AppStrings';

export async function simpleGetCall(url) {
  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

export async function simplePostCall(url, requestBody) {
  return await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //'Access-Control-Allow-Credentials': "*"
    },
    withCredentials: true,
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
  //.then(data=>data.json());
}
// export async function simplePostCall(url, requestBody) {
//   try {
//     const response = await fetch(url, {
//       method: "POST",
//       mode: "cors", // Enable Cross-Origin Resource Sharing
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//       credentials: "include", // Use 'include' to send cookies or authentication headers if needed
//       body: JSON.stringify(requestBody), // Ensure requestBody is properly stringified
//     });

//     // Check if the response status is not OK
//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }

//     // Parse the response as JSON
//     const result = await response.json();
//     return getResult(result); // Call getResult with the parsed JSON result
//   } catch (error) {
//     console.error("Error in simplePostCall:", error.message || error);
//     throw error; // Propagate the error to the caller
//   }
// }

export async function multipartPostCall(url, requestBody) {
  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      //'Content-Type': 'multipart/form-data',
      // 'Authorization': 'Token ' + await AsyncStorage.getItem(AppStrings.TOKEN)
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

export async function getWithAuthCall(url) {
  return await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //Authorization: 'Token ' + (await AsyncStorage.getItem(AppStrings.TOKEN)),
    },
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

export async function postWithAuthCall(url, requestBody) {
  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //Authorization: 'Token ' + (await AsyncStorage.getItem(AppStrings.TOKEN)),
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

export async function putWithAuthCall(url, requestBody) {
  return await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //Authorization: 'Token ' + (await AsyncStorage.getItem(AppStrings.TOKEN)),
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

export async function postMultipartWithAuthCall(url, requestBody) {
  return await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      //Authorization: 'Token ' + (await AsyncStorage.getItem(AppStrings.TOKEN)),
    },
    withCredentials: true,
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

export async function putMultipartWithAuthCall(url, requestBody) {
  return await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      //Authorization: 'Token ' + (await AsyncStorage.getItem(AppStrings.TOKEN)),
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}
export async function updateProfile(url, requestBody) {
  return await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      // Authorization: 'Token ' + (await AsyncStorage.getItem(AppStrings.token)),
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

//-------------------------------------
export async function getResult(data) {
  //console.log('--------------', data);
  return JSON.parse(data.trim());
}
//-------------------------------------
